import authAxios from 'src/modules/shared/axios/authAxios';
import { subtenantDomain } from './subTenantSubdomain';
import AuthCurrentTenant from '../auth/authCurrentTenant';
import config from 'src/config';

export default class SubTenantService {
  static async fetchAndApply() {
    const subTentantUrl =
      subtenantDomain.fromLocationHref();

    if (
      subtenantDomain.isEnabled &&
      subtenantDomain.isRootDomain
    ) {
      AuthCurrentTenant.clear();
      return;
    }

    // If there is a subdomain with the tenant url,
    // it must fetch the settings form that subdomain no matter
    // which one is on local storage
    if (subTentantUrl) {
      let currentTenant;
      try {
        currentTenant = await this.findByUrl(subTentantUrl);
      } catch (error) {
        console.error(error);
      }
      AuthCurrentTenant.set(currentTenant);
      console.log('currentTenant: ', currentTenant);
      if (!currentTenant) {
        window.location.href = `${config.frontendUrl.protocol}://${currentTenant}.${config.frontendUrl.host}`;
        return;
      }
    }

    const tenantId = AuthCurrentTenant.getSubTenantId();
    if (tenantId && subTentantUrl) {
      try {
        const currentTenant = await this.find(tenantId);
        AuthCurrentTenant.set(currentTenant);
      } catch (error) {
        console.error(error);
      }
    }
  }

  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/subTenant/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(id) {
    const response = await authAxios.delete(
      `/subTenant/${id}`,
    );

    return response;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/subTenant`,
      body,
    );

    return response.data;
  }

  static async acceptInvitation(
    token,
    forceAcceptOtherEmail = false,
  ) {
    const response = await authAxios.post(
      `/tenant/invitation/${token}/accept`,
      {
        forceAcceptOtherEmail,
      },
    );

    return response.data;
  }

  static async declineInvitation(token) {
    const params = null;

    const response = await authAxios.delete(
      `/tenant/invitation/${token}/decline`,
      {
        params,
      },
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(
      `/subTenant/${id}`,
    );
    return response.data;
  }

  static async findByUrl(url) {
    const response = await authAxios.get(
      `/subTenant/admin/${url.subTenant}/${url.tenant}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(
      `/subTenant/tenant/`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listing(id) {
    const response = await authAxios.get(
      `/subTenant/tenant/${id}`,
    );
    return response.data;
  }
}
