import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'number',
    label: i18n('entities.room.fields.number'),
    schema: schemas.integer(
      i18n('entities.room.fields.number'),
      {},
    ),
  },
  {
    name: 'roomName',
    label: i18n('entities.room.fields.roomName'),
    schema: schemas.string(
      i18n('entities.room.fields.roomName'),
      {},
    ),
  },
  {
    name: 'latitude',
    label: i18n('entities.room.fields.latitude'),
    schema: schemas.decimal(
      i18n('entities.room.fields.latitude'),
      {},
    ),
  },
  {
    name: 'longitude',
    label: i18n('entities.room.fields.longitude'),
    schema: schemas.decimal(
      i18n('entities.room.fields.longitude'),
      {},
    ),
  },
  {
    name: 'roomType',
    label: i18n('entities.room.fields.roomType'),
    schema: schemas.string(
      i18n('entities.room.fields.roomType'),
      {},
    ),
  },
];