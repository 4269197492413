import selectors from 'src/modules/tenant/list/tenantListSelectors';
import Errors from 'src/modules/shared/error/errors';
import SubTenantService from 'src/modules/subTenant/subTenantService';

const prefix = 'SUB_TENANT_LIST';

const subTenantListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,


  FETCH_LIST_STARTED: `${prefix}_FETCH_LIST_STARTED`,
  FETCH_LIST_SUCCESS: `${prefix}_FETCH_LIST_SUCCESS`,
  FETCH_LIST_ERROR: `${prefix}_FETCH_LIST_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  doClearAllSelected() {
    return {
      type: subTenantListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: subTenantListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: subTenantListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: subTenantListActions.RESETED,
    });

    dispatch(subTenantListActions.doFetch());
  },

  doChangePagination: (pagination) => async (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: subTenantListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(subTenantListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: subTenantListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(subTenantListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (
    dispatch,
    getState,
  ) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(subTenantListActions.doFetch(filter, rawFilter, true));
  },


  doList: (id) => async (dispatch) => {
    try {
      dispatch({
        type: subTenantListActions.FETCH_LIST_STARTED,
      });
      const response = await SubTenantService.listing(id);


      dispatch({
        type: subTenantListActions.FETCH_LIST_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: subTenantListActions.FETCH_LIST_ERROR,
      });
    }
  },

  doFetch: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: subTenantListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await SubTenantService.list(
        filter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );

      dispatch({
        type: subTenantListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: subTenantListActions.FETCH_ERROR,
      });
    }
  },
};

export default subTenantListActions;
