import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.sitemap.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.sitemap.fields.title'),
  },
  {
    name: 'headerImage',
    label: i18n('entities.sitemap.fields.headerImage'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'body',
    label: i18n('entities.sitemap.fields.body'),
  },
  {
    name: 'phone',
    label: i18n('entities.sitemap.fields.phone'),
  },
  {
    name: 'category',
    label: i18n('entities.sitemap.fields.category'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'place',
    label: i18n('entities.sitemap.fields.place'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'restaurant',
    label: i18n('entities.sitemap.fields.restaurant'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'sortOrder',
    label: i18n('entities.sitemap.fields.sortOrder'),
  },
  {
    name: 'body2',
    label: i18n('entities.sitemap.fields.body2'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.sitemap.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.sitemap.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
