import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.menu.fields.name'),
    schema: schemas.string(
      i18n('entities.menu.fields.name'),
      {
        "required": true,
        "min": 2,
        "max": 255
      },
    ),
  },
  {
    name: 'menuFile',
    label: i18n('entities.menu.fields.menuFile'),
    schema: schemas.files(
      i18n('entities.menu.fields.menuFile'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'restaurant',
    label: i18n('entities.menu.fields.restaurant'),
    schema: schemas.relationToOne(
      i18n('entities.menu.fields.restaurant'),
      {},
    ),
  },
  {
    name: 'place',
    label: i18n('entities.menu.fields.place'),
    schema: schemas.relationToOne(
      i18n('entities.menu.fields.place'),
      {},
    ),
  },
  {
    name: 'items',
    label: i18n('entities.menu.fields.items'),
    schema: schemas.relationToMany(
      i18n('entities.menu.fields.items'),
      {},
    ),
  },
];