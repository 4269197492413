import list from 'src/modules/tenantProfile/list/tenantProfileListReducers';
import form from 'src/modules/tenantProfile/form/tenantProfileFormReducers';
import view from 'src/modules/tenantProfile/view/tenantProfileViewReducers';
import destroy from 'src/modules/tenantProfile/destroy/tenantProfileDestroyReducers';
import importerReducer from 'src/modules/tenantProfile/importer/tenantProfileImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
