import list from 'src/modules/sitemapCategories/list/sitemapCategoriesListReducers';
import form from 'src/modules/sitemapCategories/form/sitemapCategoriesFormReducers';
import view from 'src/modules/sitemapCategories/view/sitemapCategoriesViewReducers';
import destroy from 'src/modules/sitemapCategories/destroy/sitemapCategoriesDestroyReducers';
import importerReducer from 'src/modules/sitemapCategories/importer/sitemapCategoriesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
