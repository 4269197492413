import list from 'src/modules/sitemap/list/sitemapListReducers';
import form from 'src/modules/sitemap/form/sitemapFormReducers';
import view from 'src/modules/sitemap/view/sitemapViewReducers';
import destroy from 'src/modules/sitemap/destroy/sitemapDestroyReducers';
import importerReducer from 'src/modules/sitemap/importer/sitemapImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
