import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.room.fields.id'),
  },
  {
    name: 'number',
    label: i18n('entities.room.fields.number'),
  },
  {
    name: 'roomName',
    label: i18n('entities.room.fields.roomName'),
  },
  {
    name: 'latitude',
    label: i18n('entities.room.fields.latitude'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'longitude',
    label: i18n('entities.room.fields.longitude'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'roomType',
    label: i18n('entities.room.fields.roomType'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.room.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.room.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
