import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'officialName',
    label: i18n('entities.tenantProfile.fields.officialName'),
    schema: schemas.string(
      i18n('entities.tenantProfile.fields.officialName'),
      {},
    ),
  },
  {
    name: 'shortName',
    label: i18n('entities.tenantProfile.fields.shortName'),
    schema: schemas.string(
      i18n('entities.tenantProfile.fields.shortName'),
      {},
    ),
  },
  {
    name: 'phoneNumber',
    label: i18n('entities.tenantProfile.fields.phoneNumber'),
    schema: schemas.string(
      i18n('entities.tenantProfile.fields.phoneNumber'),
      {},
    ),
  },
  {
    name: 'textNumber',
    label: i18n('entities.tenantProfile.fields.textNumber'),
    schema: schemas.string(
      i18n('entities.tenantProfile.fields.textNumber'),
      {},
    ),
  },
  {
    name: 'streetAddress1',
    label: i18n('entities.tenantProfile.fields.streetAddress1'),
    schema: schemas.string(
      i18n('entities.tenantProfile.fields.streetAddress1'),
      {},
    ),
  },
  {
    name: 'streetAddress2',
    label: i18n('entities.tenantProfile.fields.streetAddress2'),
    schema: schemas.string(
      i18n('entities.tenantProfile.fields.streetAddress2'),
      {},
    ),
  },
  {
    name: 'city',
    label: i18n('entities.tenantProfile.fields.city'),
    schema: schemas.string(
      i18n('entities.tenantProfile.fields.city'),
      {},
    ),
  },
  {
    name: 'state',
    label: i18n('entities.tenantProfile.fields.state'),
    schema: schemas.string(
      i18n('entities.tenantProfile.fields.state'),
      {},
    ),
  },
  {
    name: 'zip',
    label: i18n('entities.tenantProfile.fields.zip'),
    schema: schemas.string(
      i18n('entities.tenantProfile.fields.zip'),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n('entities.tenantProfile.fields.country'),
    schema: schemas.string(
      i18n('entities.tenantProfile.fields.country'),
      {},
    ),
  },
  {
    name: 'wallpaper',
    label: i18n('entities.tenantProfile.fields.wallpaper'),
    schema: schemas.images(
      i18n('entities.tenantProfile.fields.wallpaper'),
      {},
    ),
  },
];