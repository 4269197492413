import list from 'src/modules/room/list/roomListReducers';
import form from 'src/modules/room/form/roomFormReducers';
import view from 'src/modules/room/view/roomViewReducers';
import destroy from 'src/modules/room/destroy/roomDestroyReducers';
import importerReducer from 'src/modules/room/importer/roomImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
