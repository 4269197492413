import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.restaurant.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.restaurant.fields.name'),
  },
  {
    name: 'logo',
    label: i18n('entities.restaurant.fields.logo'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'description',
    label: i18n('entities.restaurant.fields.description'),
  },
  {
    name: 'cuisine',
    label: i18n('entities.restaurant.fields.cuisine'),
  },
  {
    name: 'priceRange',
    label: i18n('entities.restaurant.fields.priceRange'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'operatingHours',
    label: i18n('entities.restaurant.fields.operatingHours'),
  },
  {
    name: 'dressPolicy',
    label: i18n('entities.restaurant.fields.dressPolicy'),
  },
  {
    name: 'reservationLink',
    label: i18n('entities.restaurant.fields.reservationLink'),
  },
  {
    name: 'location',
    label: i18n('entities.restaurant.fields.location'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'openTime',
    label: i18n('entities.restaurant.fields.openTime'),
  },
  {
    name: 'closeTime',
    label: i18n('entities.restaurant.fields.closeTime'),
  },
  {
    name: 'mealPeriods',
    label: i18n('entities.restaurant.fields.mealPeriods'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.restaurant.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.restaurant.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
