import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/menuSection/importer/menuSectionImporterSelectors';
import MenuSectionService from 'src/modules/menuSection/menuSectionService';
import fields from 'src/modules/menuSection/importer/menuSectionImporterFields';
import { i18n } from 'src/i18n';

const menuSectionImporterActions = importerActions(
  'MENUSECTION_IMPORTER',
  selectors,
  MenuSectionService.import,
  fields,
  i18n('entities.menuSection.importer.fileName'),
);

export default menuSectionImporterActions;