import list from 'src/modules/menuItem/list/menuItemListReducers';
import form from 'src/modules/menuItem/form/menuItemFormReducers';
import view from 'src/modules/menuItem/view/menuItemViewReducers';
import destroy from 'src/modules/menuItem/destroy/menuItemDestroyReducers';
import importerReducer from 'src/modules/menuItem/importer/menuItemImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
