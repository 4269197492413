import actions from 'src/modules/subTenant/getByUrl/subTenantGetByUrlActions';

const initialData = {
  subTenant: {},
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      selectedKeys: [],
      filter: payload ? payload.filter : {},
      rawFilter: payload ? payload.rawFilter : {},
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      subTenant: payload.subTenant,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      subTenant: {},
    };
  }

  return state;
};
