import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import EventIcon from '@material-ui/icons/Event';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: <DashboardIcon />,
    label: i18n('dashboard.menu'),
    permissionRequired: null,
  },

  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: <PersonIcon />,
  },

  {
    path: '/settings',
    icon: <SettingsIcon />,
    label: i18n('settings.menu'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/sitemap',
    permissionRequired: permissions.sitemapRead,
    icon: <ChevronRightIcon />,
    label: i18n('entities.sitemap.menu'),
  },

  {
    path: '/sitemap-categories',
    permissionRequired: permissions.sitemapCategoriesRead,
    icon: <ChevronRightIcon />,
    label: i18n('entities.sitemapCategories.menu'),
  },

  {
    path: '/promotion',
    permissionRequired: permissions.promotionRead,
    icon: <LocalOfferIcon />,
    label: i18n('entities.promotion.menu'),
  },

  {
    path: '/event',
    permissionRequired: permissions.eventRead,
    icon: <EventIcon />,
    label: i18n('entities.event.menu'),
  },
].filter(Boolean);
