import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'title',
    label: i18n('entities.event.fields.title'),
    schema: schemas.string(
      i18n('entities.event.fields.title'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'headerImage',
    label: i18n('entities.event.fields.headerImage'),
    schema: schemas.images(
      i18n('entities.event.fields.headerImage'),
      {},
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.event.fields.startDate'),
    schema: schemas.datetime(
      i18n('entities.event.fields.startDate'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'endDate',
    label: i18n('entities.event.fields.endDate'),
    schema: schemas.datetime(
      i18n('entities.event.fields.endDate'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'allDay',
    label: i18n('entities.event.fields.allDay'),
    schema: schemas.boolean(
      i18n('entities.event.fields.allDay'),
      {},
    ),
  },
  {
    name: 'dayOfWeek',
    label: i18n('entities.event.fields.dayOfWeek'),
    schema: schemas.stringArray(
      i18n('entities.event.fields.dayOfWeek'),
      {},
    ),
  },
  {
    name: 'endRecur',
    label: i18n('entities.event.fields.endRecur'),
    schema: schemas.datetime(
      i18n('entities.event.fields.endRecur'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'description',
    label: i18n('entities.event.fields.description'),
    schema: schemas.string(
      i18n('entities.event.fields.description'),
      {},
    ),
  },
  {
    name: 'price',
    label: i18n('entities.event.fields.price'),
    schema: schemas.string(
      i18n('entities.event.fields.price'),
      {},
    ),
  },
  {
    name: 'notes',
    label: i18n('entities.event.fields.notes'),
    schema: schemas.string(
      i18n('entities.event.fields.notes'),
      {},
    ),
  },
  {
    name: 'location',
    label: i18n('entities.event.fields.location'),
    schema: schemas.relationToOne(
      i18n('entities.event.fields.location'),
      {},
    ),
  },
  {
    name: 'host',
    label: i18n('entities.event.fields.host'),
    schema: schemas.relationToOne(
      i18n('entities.event.fields.host'),
      {},
    ),
  },
  {
    name: 'bookingLink',
    label: i18n('entities.event.fields.bookingLink'),
    schema: schemas.string(
      i18n('entities.event.fields.bookingLink'),
      {},
    ),
  },
  {
    name: 'bookingLabel',
    label: i18n('entities.event.fields.bookingLabel'),
    schema: schemas.string(
      i18n('entities.event.fields.bookingLabel'),
      {},
    ),
  },
];