/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
 export default class Storage {
  static get values() {
    return {
      restaurantLogo: {
        id: 'restaurantLogo',
        folder: 'tenant/:tenantId/restaurant/logo',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      menuMenuFile: {
        id: 'menuMenuFile',
        folder: 'tenant/:tenantId/menu/menuFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      promotionAttachment: {
        id: 'promotionAttachment',
        folder: 'tenant/:tenantId/promotion/attachment',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      promotionCoverImage: {
        id: 'promotionCoverImage',
        folder: 'tenant/:tenantId/promotion/coverImage',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      sitemapHeaderImage: {
        id: 'sitemapHeaderImage',
        folder: 'tenant/:tenantId/sitemap/headerImage',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      tenantProfileWallpaper: {
        id: 'tenantProfileWallpaper',
        folder: 'tenant/:tenantId/tenantProfile/wallpaper',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      onlineOrderImage: {
        id: 'onlineOrderImage',
        folder: 'tenant/:tenantId/onlineOrder/image',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      eventHeaderImage: {
        id: 'eventHeaderImage',
        folder: 'tenant/:tenantId/event/headerImage',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      hostHeadshot: {
        id: 'hostHeadshot',
        folder: 'tenant/:tenantId/host/headshot',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder:
          'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
    };
  }
}
