import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.onlineOrder.fields.name'),
    schema: schemas.string(
      i18n('entities.onlineOrder.fields.name'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'url',
    label: i18n('entities.onlineOrder.fields.url'),
    schema: schemas.string(
      i18n('entities.onlineOrder.fields.url'),
      {},
    ),
  },
  {
    name: 'image',
    label: i18n('entities.onlineOrder.fields.image'),
    schema: schemas.images(
      i18n('entities.onlineOrder.fields.image'),
      {},
    ),
  },
  {
    name: 'sortOrder',
    label: i18n('entities.onlineOrder.fields.sortOrder'),
    schema: schemas.integer(
      i18n('entities.onlineOrder.fields.sortOrder'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'subhead',
    label: i18n('entities.onlineOrder.fields.subhead'),
    schema: schemas.string(
      i18n('entities.onlineOrder.fields.subhead'),
      {},
    ),
  },
  {
    name: 'teaser',
    label: i18n('entities.onlineOrder.fields.teaser'),
    schema: schemas.string(
      i18n('entities.onlineOrder.fields.teaser'),
      {},
    ),
  },
];