import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/sitemapCategories/importer/sitemapCategoriesImporterSelectors';
import SitemapCategoriesService from 'src/modules/sitemapCategories/sitemapCategoriesService';
import fields from 'src/modules/sitemapCategories/importer/sitemapCategoriesImporterFields';
import { i18n } from 'src/i18n';

const sitemapCategoriesImporterActions = importerActions(
  'SITEMAPCATEGORIES_IMPORTER',
  selectors,
  SitemapCategoriesService.import,
  fields,
  i18n('entities.sitemapCategories.importer.fileName'),
);

export default sitemapCategoriesImporterActions;