import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.restaurant.fields.name'),
    schema: schemas.string(
      i18n('entities.restaurant.fields.name'),
      {
        "required": true,
        "min": 2,
        "max": 255
      },
    ),
  },
  {
    name: 'logo',
    label: i18n('entities.restaurant.fields.logo'),
    schema: schemas.images(
      i18n('entities.restaurant.fields.logo'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n('entities.restaurant.fields.description'),
    schema: schemas.string(
      i18n('entities.restaurant.fields.description'),
      {},
    ),
  },
  {
    name: 'cuisine',
    label: i18n('entities.restaurant.fields.cuisine'),
    schema: schemas.string(
      i18n('entities.restaurant.fields.cuisine'),
      {},
    ),
  },
  {
    name: 'priceRange',
    label: i18n('entities.restaurant.fields.priceRange'),
    schema: schemas.stringArray(
      i18n('entities.restaurant.fields.priceRange'),
      {},
    ),
  },
  {
    name: 'operatingHours',
    label: i18n('entities.restaurant.fields.operatingHours'),
    schema: schemas.string(
      i18n('entities.restaurant.fields.operatingHours'),
      {},
    ),
  },
  {
    name: 'dressPolicy',
    label: i18n('entities.restaurant.fields.dressPolicy'),
    schema: schemas.string(
      i18n('entities.restaurant.fields.dressPolicy'),
      {},
    ),
  },
  {
    name: 'reservationLink',
    label: i18n('entities.restaurant.fields.reservationLink'),
    schema: schemas.string(
      i18n('entities.restaurant.fields.reservationLink'),
      {},
    ),
  },
  {
    name: 'location',
    label: i18n('entities.restaurant.fields.location'),
    schema: schemas.relationToOne(
      i18n('entities.restaurant.fields.location'),
      {},
    ),
  },
  {
    name: 'openTime',
    label: i18n('entities.restaurant.fields.openTime'),
    schema: schemas.string(
      i18n('entities.restaurant.fields.openTime'),
      {},
    ),
  },
  {
    name: 'closeTime',
    label: i18n('entities.restaurant.fields.closeTime'),
    schema: schemas.string(
      i18n('entities.restaurant.fields.closeTime'),
      {},
    ),
  },
  {
    name: 'mealPeriods',
    label: i18n('entities.restaurant.fields.mealPeriods'),
    schema: schemas.relationToMany(
      i18n('entities.restaurant.fields.mealPeriods'),
      {},
    ),
  },
];