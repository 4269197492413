import invitation from 'src/modules/subTenant/invitation/subTenantInvitationReducers';
import list from 'src/modules/subTenant/list/subTenantListReducers';
import form from 'src/modules/subTenant/form/subTenantFormReducers';

import destroy from 'src/modules/subTenant/destroy/subTenantDestroyReducers';
import getByUrl from "src/modules/subTenant/getByUrl/subTenantGetByUrlReducers";
import { combineReducers } from 'redux';

export default combineReducers({
  invitation,
  list,
  form,
  destroy,
  getByUrl,
});

