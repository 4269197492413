import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/sitemap/importer/sitemapImporterSelectors';
import SitemapService from 'src/modules/sitemap/sitemapService';
import fields from 'src/modules/sitemap/importer/sitemapImporterFields';
import { i18n } from 'src/i18n';

const sitemapImporterActions = importerActions(
  'SITEMAP_IMPORTER',
  selectors,
  SitemapService.import,
  fields,
  i18n('entities.sitemap.importer.fileName'),
);

export default sitemapImporterActions;