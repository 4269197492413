import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.place.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.place.fields.name'),
  },
  {
    name: 'latitude',
    label: i18n('entities.place.fields.latitude'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'longitude',
    label: i18n('entities.place.fields.longitude'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'operatingHours',
    label: i18n('entities.place.fields.operatingHours'),
  },
  {
    name: 'dressPolicy',
    label: i18n('entities.place.fields.dressPolicy'),
  },
  {
    name: 'bookingLink',
    label: i18n('entities.place.fields.bookingLink'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.place.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.place.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
