import list from 'src/modules/menu/list/menuListReducers';
import form from 'src/modules/menu/form/menuFormReducers';
import view from 'src/modules/menu/view/menuViewReducers';
import destroy from 'src/modules/menu/destroy/menuDestroyReducers';
import importerReducer from 'src/modules/menu/importer/menuImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
