import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import authActions from 'src/modules/auth/authActions';
import SubTenantService from 'src/modules/subTenant/subTenantService';

const prefix = 'SUB_TENANT_FORM';

const subTenantFormActions = {
  RESET: `${prefix}_RESET`,

  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: subTenantFormActions.INIT_STARTED,
      });

      const isEdit = Boolean(id);

      let record = {};

      if (isEdit) {
        record = await SubTenantService.find(id);
      }

      dispatch({
        type: subTenantFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: subTenantFormActions.INIT_ERROR,
      });
      getHistory().push('/sub-tenant');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: subTenantFormActions.CREATE_STARTED,
      });

      const tenant = await SubTenantService.create(values);
      dispatch({
        type: subTenantFormActions.CREATE_SUCCESS,
        payload:tenant
      });
      getHistory().push("/sub-tenant")
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: subTenantFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: subTenantFormActions.UPDATE_STARTED,
      });

      await SubTenantService.update(
        id,
        values,
      );
      dispatch({
        type: subTenantFormActions.UPDATE_SUCCESS,
      });
      getHistory().push("/sub-tenant")

      Message.success(i18n('tenant.update.success'));
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: subTenantFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default subTenantFormActions;
