import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/onlineOrder/importer/onlineOrderImporterSelectors';
import OnlineOrderService from 'src/modules/onlineOrder/onlineOrderService';
import fields from 'src/modules/onlineOrder/importer/onlineOrderImporterFields';
import { i18n } from 'src/i18n';

const onlineOrderImporterActions = importerActions(
  'ONLINEORDER_IMPORTER',
  selectors,
  OnlineOrderService.import,
  fields,
  i18n('entities.onlineOrder.importer.fileName'),
);

export default onlineOrderImporterActions;