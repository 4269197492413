import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.tenantProfile.fields.id'),
  },
  {
    name: 'officialName',
    label: i18n('entities.tenantProfile.fields.officialName'),
  },
  {
    name: 'shortName',
    label: i18n('entities.tenantProfile.fields.shortName'),
  },
  {
    name: 'phoneNumber',
    label: i18n('entities.tenantProfile.fields.phoneNumber'),
  },
  {
    name: 'textNumber',
    label: i18n('entities.tenantProfile.fields.textNumber'),
  },
  {
    name: 'streetAddress1',
    label: i18n('entities.tenantProfile.fields.streetAddress1'),
  },
  {
    name: 'streetAddress2',
    label: i18n('entities.tenantProfile.fields.streetAddress2'),
  },
  {
    name: 'city',
    label: i18n('entities.tenantProfile.fields.city'),
  },
  {
    name: 'state',
    label: i18n('entities.tenantProfile.fields.state'),
  },
  {
    name: 'zip',
    label: i18n('entities.tenantProfile.fields.zip'),
  },
  {
    name: 'country',
    label: i18n('entities.tenantProfile.fields.country'),
  },
  {
    name: 'wallpaper',
    label: i18n('entities.tenantProfile.fields.wallpaper'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.tenantProfile.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.tenantProfile.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
