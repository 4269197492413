import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.promotion.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.promotion.fields.title'),
  },
  {
    name: 'teaser',
    label: i18n('entities.promotion.fields.teaser'),
  },
  {
    name: 'startDate',
    label: i18n('entities.promotion.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endDate',
    label: i18n('entities.promotion.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'attachment',
    label: i18n('entities.promotion.fields.attachment'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'redirectURL',
    label: i18n('entities.promotion.fields.redirectURL'),
  },
  {
    name: 'coverImage',
    label: i18n('entities.promotion.fields.coverImage'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.promotion.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.promotion.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
