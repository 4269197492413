import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/room/importer/roomImporterSelectors';
import RoomService from 'src/modules/room/roomService';
import fields from 'src/modules/room/importer/roomImporterFields';
import { i18n } from 'src/i18n';

const roomImporterActions = importerActions(
  'ROOM_IMPORTER',
  selectors,
  RoomService.import,
  fields,
  i18n('entities.room.importer.fileName'),
);

export default roomImporterActions;