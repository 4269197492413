import authActions from 'src/modules/auth/authActions';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';
import listActions from 'src/modules/subTenant/list/subTenantListActions';
import SubTenantService from 'src/modules/subTenant/subTenantService';

const prefix = 'SUB_TENANT_DESTROY';

const subTenantDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: subTenantDestroyActions.DESTROY_STARTED,
      });

      await SubTenantService.destroyAll(id);

      dispatch({
        type: subTenantDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(i18n('subTenant.destroy.success'));

      await dispatch(authActions.doRefreshCurrentUser());

      getHistory().push('/sub-tenant');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: subTenantDestroyActions.DESTROY_ERROR,
      });
    }
  },
};

export default subTenantDestroyActions;
