import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/tenantProfile/importer/tenantProfileImporterSelectors';
import TenantProfileService from 'src/modules/tenantProfile/tenantProfileService';
import fields from 'src/modules/tenantProfile/importer/tenantProfileImporterFields';
import { i18n } from 'src/i18n';

const tenantProfileImporterActions = importerActions(
  'TENANTPROFILE_IMPORTER',
  selectors,
  TenantProfileService.import,
  fields,
  i18n('entities.tenantProfile.importer.fileName'),
);

export default tenantProfileImporterActions;