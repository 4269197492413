import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.host.fields.name'),
    schema: schemas.string(
      i18n('entities.host.fields.name'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'title',
    label: i18n('entities.host.fields.title'),
    schema: schemas.string(
      i18n('entities.host.fields.title'),
      {},
    ),
  },
  {
    name: 'bio',
    label: i18n('entities.host.fields.bio'),
    schema: schemas.string(
      i18n('entities.host.fields.bio'),
      {},
    ),
  },
  {
    name: 'headshot',
    label: i18n('entities.host.fields.headshot'),
    schema: schemas.images(
      i18n('entities.host.fields.headshot'),
      {},
    ),
  },
];