import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/menu/importer/menuImporterSelectors';
import MenuService from 'src/modules/menu/menuService';
import fields from 'src/modules/menu/importer/menuImporterFields';
import { i18n } from 'src/i18n';

const menuImporterActions = importerActions(
  'MENU_IMPORTER',
  selectors,
  MenuService.import,
  fields,
  i18n('entities.menu.importer.fileName'),
);

export default menuImporterActions;