import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'title',
    label: i18n('entities.promotion.fields.title'),
    schema: schemas.string(
      i18n('entities.promotion.fields.title'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'teaser',
    label: i18n('entities.promotion.fields.teaser'),
    schema: schemas.string(
      i18n('entities.promotion.fields.teaser'),
      {
        "required": false
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.promotion.fields.startDate'),
    schema: schemas.datetime(
      i18n('entities.promotion.fields.startDate'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'endDate',
    label: i18n('entities.promotion.fields.endDate'),
    schema: schemas.datetime(
      i18n('entities.promotion.fields.endDate'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'attachment',
    label: i18n('entities.promotion.fields.attachment'),
    schema: schemas.files(
      i18n('entities.promotion.fields.attachment'),
      {},
    ),
  },
  {
    name: 'redirectURL',
    label: i18n('entities.promotion.fields.redirectURL'),
    schema: schemas.string(
      i18n('entities.promotion.fields.redirectURL'),
      {},
    ),
  },
  {
    name: 'coverImage',
    label: i18n('entities.promotion.fields.coverImage'),
    schema: schemas.images(
      i18n('entities.promotion.fields.coverImage'),
      {},
    ),
  },
];