import layoutActions from 'src/modules/layout/layoutActions';
import { useDispatch, useSelector } from 'react-redux';
import I18nSelect from 'src/view/layout/I18nSelect';
import {
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import UserMenu from 'src/view/layout/UserMenu';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
import selectors from 'src/modules/auth/authSelectors';
import subTenantselectors from 'src/modules/subTenant/list/subTenantListSelectors';

import actions from 'src/modules/subTenant/list/subTenantListActions';

import { Autocomplete } from '@material-ui/lab';
import authSelectors from 'src/modules/auth/authSelectors';
import { useEffect } from 'react';
import authActions from 'src/modules/auth/authActions';

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: theme.palette.getContrastText(
      theme.palette.primary.main,
    ),
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
    fontSize: '1.5em',
    color: theme.palette.getContrastText(
      theme.palette.primary.main,
    ),
    textDecoration: 'none',
  },
  grow: {
    flex: '1 1 auto',
  },
  logoImage: {
    width:"64px",
    height:"40px",
    objectFit:"cover"
  },

  root: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      margin: '0 15px',
      width: '190px',
      color: 'white',
      '& .MuiInputBase-input': {
        padding: theme.spacing(1),
        color: 'white',
      },
    },
  },
}));

function Header(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isSubTenant = useSelector(
    authSelectors.checkIsSubTenantExist,
  );

  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );

  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const rows = useSelector(subTenantselectors.selectRows);
  const logoUrl = useSelector(selectors.selectLogoUrl);
  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  useEffect(() => {
    if (isSubTenant) {
      const { parentTenant } = currentTenant;
      const { id } = parentTenant;
      dispatch(actions.doList(id));
    }
  }, [currentTenant, dispatch, isSubTenant]);

  const handleSelect = (e) => {
    const value = e.target.value;
    const subTenant = currentUser.subTenants.find(
      (sub) => sub.name === value,
    );
    if (subTenant)
      dispatch(
        authActions.doSelectTenant({
          ...subTenant,
          parentTenantName: subTenant.parentTenant.name,
        }),
      );
  };

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar>
        <IconButton
          edge="start"
          onClick={doToggleMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>

        <Link className={classes.logo} to="/">
          {logoUrl ? (
            <img
              src={logoUrl}
              className={classes.logoImage}
              alt={i18n('app.title')}
              style={{ verticalAlign: 'middle' }}
            />
          ) : (
            <>{i18n('app.title')}</>
          )}
        </Link>

        <div className={classes.grow} />

        {isSubTenant && !currentUser?.subTenant && (
          <>
            <div>SUBTENANT</div>
            <Autocomplete
              options={rows || []}
              getOptionLabel={(option: any) => option.name}
              classes={{ root: classes.root }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Sub Tenant"
                  onSelect={handleSelect}
                />
              )}
            />
          </>
        )}
        <I18nSelect />

        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
