import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.place.fields.name'),
    schema: schemas.string(
      i18n('entities.place.fields.name'),
      {},
    ),
  },
  {
    name: 'latitude',
    label: i18n('entities.place.fields.latitude'),
    schema: schemas.decimal(
      i18n('entities.place.fields.latitude'),
      {},
    ),
  },
  {
    name: 'longitude',
    label: i18n('entities.place.fields.longitude'),
    schema: schemas.decimal(
      i18n('entities.place.fields.longitude'),
      {},
    ),
  },
  {
    name: 'operatingHours',
    label: i18n('entities.place.fields.operatingHours'),
    schema: schemas.string(
      i18n('entities.place.fields.operatingHours'),
      {},
    ),
  },
  {
    name: 'dressPolicy',
    label: i18n('entities.place.fields.dressPolicy'),
    schema: schemas.string(
      i18n('entities.place.fields.dressPolicy'),
      {},
    ),
  },
  {
    name: 'bookingLink',
    label: i18n('entities.place.fields.bookingLink'),
    schema: schemas.string(
      i18n('entities.place.fields.bookingLink'),
      {},
    ),
  },
];