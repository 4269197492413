import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'title',
    label: i18n('entities.sitemap.fields.title'),
    schema: schemas.string(
      i18n('entities.sitemap.fields.title'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'headerImage',
    label: i18n('entities.sitemap.fields.headerImage'),
    schema: schemas.images(
      i18n('entities.sitemap.fields.headerImage'),
      {},
    ),
  },
  {
    name: 'body',
    label: i18n('entities.sitemap.fields.body'),
    schema: schemas.string(
      i18n('entities.sitemap.fields.body'),
      {},
    ),
  },
  {
    name: 'phone',
    label: i18n('entities.sitemap.fields.phone'),
    schema: schemas.string(
      i18n('entities.sitemap.fields.phone'),
      {},
    ),
  },
  {
    name: 'category',
    label: i18n('entities.sitemap.fields.category'),
    schema: schemas.relationToOne(
      i18n('entities.sitemap.fields.category'),
      {},
    ),
  },
  {
    name: 'place',
    label: i18n('entities.sitemap.fields.place'),
    schema: schemas.relationToOne(
      i18n('entities.sitemap.fields.place'),
      {},
    ),
  },
  {
    name: 'restaurant',
    label: i18n('entities.sitemap.fields.restaurant'),
    schema: schemas.relationToOne(
      i18n('entities.sitemap.fields.restaurant'),
      {},
    ),
  },
  {
    name: 'sortOrder',
    label: i18n('entities.sitemap.fields.sortOrder'),
    schema: schemas.integer(
      i18n('entities.sitemap.fields.sortOrder'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'body2',
    label: i18n('entities.sitemap.fields.body2'),
    schema: schemas.string(
      i18n('entities.sitemap.fields.body2'),
      {},
    ),
  },
];