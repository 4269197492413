import Errors from 'src/modules/shared/error/errors';
import SubTenantService from 'src/modules/subTenant/subTenantService';

const prefix = 'SUB_TENANT_GET_BY_URL';

const subTenantGetByUrlActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  doFind: (url) => async (dispatch) => {
    try {
      dispatch({
        type: subTenantGetByUrlActions.FETCH_STARTED,
      });
      const response = await SubTenantService.findByUrl(
        url,
      );

      dispatch({
        type: subTenantGetByUrlActions.FETCH_SUCCESS,
        payload: {
          subTenant: response,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: subTenantGetByUrlActions.FETCH_ERROR,
      });
    }
  },
};

export default subTenantGetByUrlActions;
