import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import PagesIcon from '@material-ui/icons/Pages';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import HotelIcon from '@material-ui/icons/Hotel';
import PinDropIcon from '@material-ui/icons/PinDrop';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StorefrontIcon from '@material-ui/icons/Storefront';
import EventIcon from '@material-ui/icons/Event';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import config from 'src/config';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: <DashboardIcon />,
    label: i18n('dashboard.menu'),
    permissionRequired: null,
  },

  config.isPlanEnabled && {
    path: '/plan',
    permissionRequired: permissions.planRead,
    icon: <CreditCardOutlinedIcon />,
    label: i18n('plan.menu'),
  },

  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: <PersonIcon />,
  },

  {
    path: '/audit-logs',
    icon: <HistoryIcon />,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    icon: <SettingsIcon />,
    label: i18n('settings.menu'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/tenant-profile',
    permissionRequired: permissions.tenantProfileRead,
    icon: <StorefrontIcon />,
    label: i18n('entities.tenantProfile.menu'),
  },
  {
    path: '/sub-tenant',
    permissionRequired: permissions.tenantProfileRead,
    icon: <StorefrontIcon />,
    label: i18n('subTenants.menu'),
  },

  {
    path: '/sitemap',
    permissionRequired: permissions.sitemapRead,
    icon: <PagesIcon />,
    label: i18n('entities.sitemap.menu'),
  },

  {
    path: '/sitemap-categories',
    permissionRequired: permissions.sitemapCategoriesRead,
    icon: <ChevronRightIcon />,
    label: i18n('entities.sitemapCategories.menu'),
  },

  {
    path: '/online-order',
    permissionRequired: permissions.onlineOrderRead,
    icon: <ShoppingCartIcon />,
    label: i18n('entities.onlineOrder.menu'),
  },

  {
    path: '/restaurant',
    permissionRequired: permissions.restaurantRead,
    icon: <RestaurantIcon />,
    label: i18n('entities.restaurant.menu'),
  },

  {
    path: '/menu',
    permissionRequired: permissions.menuRead,
    icon: <MenuBookIcon />,
    label: i18n('entities.menu.menu'),
  },
/*
  {
    path: '/menu-item',
    permissionRequired: permissions.menuItemRead,
    icon: <ChevronRightIcon />,
    label: i18n('entities.menuItem.menu'),
  },

  {
    path: '/menu-section',
    permissionRequired: permissions.menuSectionRead,
    icon: <ChevronRightIcon />,
    label: i18n('entities.menuSection.menu'),
  },
*/
  {
    path: '/room',
    permissionRequired: permissions.roomRead,
    icon: <HotelIcon />,
    label: i18n('entities.room.menu'),
  },

  {
    path: '/place',
    permissionRequired: permissions.placeRead,
    icon: <PinDropIcon />,
    label: i18n('entities.place.menu'),
  },

  {
    path: '/promotion',
    permissionRequired: permissions.promotionRead,
    icon: <LocalOfferIcon />,
    label: i18n('entities.promotion.menu'),
  },

  {
    path: '/event',
    permissionRequired: permissions.eventRead,
    icon: <EventIcon />,
    label: i18n('entities.event.menu'),
  },

  {
    path: '/host',
    permissionRequired: permissions.hostRead,
    icon: <AccountBoxIcon />,
    label: i18n('entities.host.menu'),
  },

].filter(Boolean);
