import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'category',
    label: i18n('entities.sitemapCategories.fields.category'),
    schema: schemas.string(
      i18n('entities.sitemapCategories.fields.category'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'sortOrder',
    label: i18n('entities.sitemapCategories.fields.sortOrder'),
    schema: schemas.integer(
      i18n('entities.sitemapCategories.fields.sortOrder'),
      {
        "required": true
      },
    ),
  },
];