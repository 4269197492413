/**
 * List of Plans
 */
class Plans {
  static get values() {
    return {
      free: 'free',
      growth: 'growth',
      enterprise: 'enterprise',
      monthly: 'monthly',
      annual: 'annual',
      custom: 'custom',
    };
  }
}

export default Plans;
