import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.menu.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.menu.fields.name'),
  },
  {
    name: 'menuFile',
    label: i18n('entities.menu.fields.menuFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'restaurant',
    label: i18n('entities.menu.fields.restaurant'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'place',
    label: i18n('entities.menu.fields.place'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'items',
    label: i18n('entities.menu.fields.items'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.menu.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.menu.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
