import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/host/importer/hostImporterSelectors';
import HostService from 'src/modules/host/hostService';
import fields from 'src/modules/host/importer/hostImporterFields';
import { i18n } from 'src/i18n';

const hostImporterActions = importerActions(
  'HOST_IMPORTER',
  selectors,
  HostService.import,
  fields,
  i18n('entities.host.importer.fileName'),
);

export default hostImporterActions;