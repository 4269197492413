import list from 'src/modules/onlineOrder/list/onlineOrderListReducers';
import form from 'src/modules/onlineOrder/form/onlineOrderFormReducers';
import view from 'src/modules/onlineOrder/view/onlineOrderViewReducers';
import destroy from 'src/modules/onlineOrder/destroy/onlineOrderDestroyReducers';
import importerReducer from 'src/modules/onlineOrder/importer/onlineOrderImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
