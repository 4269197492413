import Permissions from 'src/security/permissions';
import config from 'src/config';
const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/sub-tenant',
    loader: () =>
      import('src/view/subtenant/list/SubtenantList'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/sub-tenant/new',
    loader: () =>
      import('src/view/subtenant/form/SubTenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/sub-tenant/view/:url',
    loader: () =>
      import('src/view/subtenant/view/SubTenantAdmin'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/sub-tenant/:id/edit',
    loader: () =>
      import('src/view/subtenant/form/SubTenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/restaurant',
    loader: () =>
      import('src/view/restaurant/list/RestaurantListPage'),
    permissionRequired: permissions.restaurantRead,
    exact: true,
  },
  {
    path: '/restaurant/new',
    loader: () =>
      import('src/view/restaurant/form/RestaurantFormPage'),
    permissionRequired: permissions.restaurantCreate,
    exact: true,
  },
  {
    path: '/restaurant/importer',
    loader: () =>
      import(
        'src/view/restaurant/importer/RestaurantImporterPage'
      ),
    permissionRequired: permissions.restaurantImport,
    exact: true,
  },
  {
    path: '/restaurant/:id/edit',
    loader: () =>
      import('src/view/restaurant/form/RestaurantFormPage'),
    permissionRequired: permissions.restaurantEdit,
    exact: true,
  },
  {
    path: '/restaurant/:id',
    loader: () =>
      import('src/view/restaurant/view/RestaurantViewPage'),
    permissionRequired: permissions.restaurantRead,
    exact: true,
  },

  {
    path: '/menu',
    loader: () => import('src/view/menu/list/MenuListPage'),
    permissionRequired: permissions.menuRead,
    exact: true,
  },
  {
    path: '/menu/new',
    loader: () => import('src/view/menu/form/MenuFormPage'),
    permissionRequired: permissions.menuCreate,
    exact: true,
  },
  {
    path: '/menu/importer',
    loader: () =>
      import('src/view/menu/importer/MenuImporterPage'),
    permissionRequired: permissions.menuImport,
    exact: true,
  },
  {
    path: '/menu/:id/edit',
    loader: () => import('src/view/menu/form/MenuFormPage'),
    permissionRequired: permissions.menuEdit,
    exact: true,
  },
  {
    path: '/menu/:id',
    loader: () => import('src/view/menu/view/MenuViewPage'),
    permissionRequired: permissions.menuRead,
    exact: true,
  },

  {
    path: '/menu-item',
    loader: () =>
      import('src/view/menuItem/list/MenuItemListPage'),
    permissionRequired: permissions.menuItemRead,
    exact: true,
  },
  {
    path: '/menu-item/new',
    loader: () =>
      import('src/view/menuItem/form/MenuItemFormPage'),
    permissionRequired: permissions.menuItemCreate,
    exact: true,
  },
  {
    path: '/menu-item/importer',
    loader: () =>
      import(
        'src/view/menuItem/importer/MenuItemImporterPage'
      ),
    permissionRequired: permissions.menuItemImport,
    exact: true,
  },
  {
    path: '/menu-item/:id/edit',
    loader: () =>
      import('src/view/menuItem/form/MenuItemFormPage'),
    permissionRequired: permissions.menuItemEdit,
    exact: true,
  },
  {
    path: '/menu-item/:id',
    loader: () =>
      import('src/view/menuItem/view/MenuItemViewPage'),
    permissionRequired: permissions.menuItemRead,
    exact: true,
  },

  {
    path: '/menu-section',
    loader: () =>
      import(
        'src/view/menuSection/list/MenuSectionListPage'
      ),
    permissionRequired: permissions.menuSectionRead,
    exact: true,
  },
  {
    path: '/menu-section/new',
    loader: () =>
      import(
        'src/view/menuSection/form/MenuSectionFormPage'
      ),
    permissionRequired: permissions.menuSectionCreate,
    exact: true,
  },
  {
    path: '/menu-section/importer',
    loader: () =>
      import(
        'src/view/menuSection/importer/MenuSectionImporterPage'
      ),
    permissionRequired: permissions.menuSectionImport,
    exact: true,
  },
  {
    path: '/menu-section/:id/edit',
    loader: () =>
      import(
        'src/view/menuSection/form/MenuSectionFormPage'
      ),
    permissionRequired: permissions.menuSectionEdit,
    exact: true,
  },
  {
    path: '/menu-section/:id',
    loader: () =>
      import(
        'src/view/menuSection/view/MenuSectionViewPage'
      ),
    permissionRequired: permissions.menuSectionRead,
    exact: true,
  },

  {
    path: '/room',
    loader: () => import('src/view/room/list/RoomListPage'),
    permissionRequired: permissions.roomRead,
    exact: true,
  },
  {
    path: '/room/new',
    loader: () => import('src/view/room/form/RoomFormPage'),
    permissionRequired: permissions.roomCreate,
    exact: true,
  },
  {
    path: '/room/importer',
    loader: () =>
      import('src/view/room/importer/RoomImporterPage'),
    permissionRequired: permissions.roomImport,
    exact: true,
  },
  {
    path: '/room/:id/edit',
    loader: () => import('src/view/room/form/RoomFormPage'),
    permissionRequired: permissions.roomEdit,
    exact: true,
  },
  {
    path: '/room/:id',
    loader: () => import('src/view/room/view/RoomViewPage'),
    permissionRequired: permissions.roomRead,
    exact: true,
  },

  {
    path: '/place',
    loader: () =>
      import('src/view/place/list/PlaceListPage'),
    permissionRequired: permissions.placeRead,
    exact: true,
  },
  {
    path: '/place/new',
    loader: () =>
      import('src/view/place/form/PlaceFormPage'),
    permissionRequired: permissions.placeCreate,
    exact: true,
  },
  {
    path: '/place/importer',
    loader: () =>
      import('src/view/place/importer/PlaceImporterPage'),
    permissionRequired: permissions.placeImport,
    exact: true,
  },
  {
    path: '/place/:id/edit',
    loader: () =>
      import('src/view/place/form/PlaceFormPage'),
    permissionRequired: permissions.placeEdit,
    exact: true,
  },
  {
    path: '/place/:id',
    loader: () =>
      import('src/view/place/view/PlaceViewPage'),
    permissionRequired: permissions.placeRead,
    exact: true,
  },

  {
    path: '/promotion',
    loader: () =>
      import('src/view/promotion/list/PromotionListPage'),
    permissionRequired: permissions.promotionRead,
    exact: true,
  },
  {
    path: '/promotion/new',
    loader: () =>
      import('src/view/promotion/form/PromotionFormPage'),
    permissionRequired: permissions.promotionCreate,
    exact: true,
  },
  {
    path: '/promotion/importer',
    loader: () =>
      import(
        'src/view/promotion/importer/PromotionImporterPage'
      ),
    permissionRequired: permissions.promotionImport,
    exact: true,
  },
  {
    path: '/promotion/:id/edit',
    loader: () =>
      import('src/view/promotion/form/PromotionFormPage'),
    permissionRequired: permissions.promotionEdit,
    exact: true,
  },
  {
    path: '/promotion/:id',
    loader: () =>
      import('src/view/promotion/view/PromotionViewPage'),
    permissionRequired: permissions.promotionRead,
    exact: true,
  },

  {
    path: '/sitemap',
    loader: () =>
      import('src/view/sitemap/list/SitemapListPage'),
    permissionRequired: permissions.sitemapRead,
    exact: true,
  },
  {
    path: '/sitemap/new',
    loader: () =>
      import('src/view/sitemap/form/SitemapFormPage'),
    permissionRequired: permissions.sitemapCreate,
    exact: true,
  },
  {
    path: '/sitemap/importer',
    loader: () =>
      import(
        'src/view/sitemap/importer/SitemapImporterPage'
      ),
    permissionRequired: permissions.sitemapImport,
    exact: true,
  },
  {
    path: '/sitemap/:id/edit',
    loader: () =>
      import('src/view/sitemap/form/SitemapFormPage'),
    permissionRequired: permissions.sitemapEdit,
    exact: true,
  },
  {
    path: '/sitemap/:id',
    loader: () =>
      import('src/view/sitemap/view/SitemapViewPage'),
    permissionRequired: permissions.sitemapRead,
    exact: true,
  },

  {
    path: '/sitemap-categories',
    loader: () =>
      import(
        'src/view/sitemapCategories/list/SitemapCategoriesListPage'
      ),
    permissionRequired: permissions.sitemapCategoriesRead,
    exact: true,
  },
  {
    path: '/sitemap-categories/new',
    loader: () =>
      import(
        'src/view/sitemapCategories/form/SitemapCategoriesFormPage'
      ),
    permissionRequired: permissions.sitemapCategoriesCreate,
    exact: true,
  },
  {
    path: '/sitemap-categories/importer',
    loader: () =>
      import(
        'src/view/sitemapCategories/importer/SitemapCategoriesImporterPage'
      ),
    permissionRequired: permissions.sitemapCategoriesImport,
    exact: true,
  },
  {
    path: '/sitemap-categories/:id/edit',
    loader: () =>
      import(
        'src/view/sitemapCategories/form/SitemapCategoriesFormPage'
      ),
    permissionRequired: permissions.sitemapCategoriesEdit,
    exact: true,
  },
  {
    path: '/sitemap-categories/:id',
    loader: () =>
      import(
        'src/view/sitemapCategories/view/SitemapCategoriesViewPage'
      ),
    permissionRequired: permissions.sitemapCategoriesRead,
    exact: true,
  },

  {
    path: '/tenant-profile',
    loader: () =>
      import(
        'src/view/tenantProfile/list/TenantProfileListPage'
      ),
    permissionRequired: permissions.tenantProfileRead,
    exact: true,
  },
  {
    path: '/tenant-profile/new',
    loader: () =>
      import(
        'src/view/tenantProfile/form/TenantProfileFormPage'
      ),
    permissionRequired: permissions.tenantProfileCreate,
    exact: true,
  },
  {
    path: '/tenant-profile/importer',
    loader: () =>
      import(
        'src/view/tenantProfile/importer/TenantProfileImporterPage'
      ),
    permissionRequired: permissions.tenantProfileImport,
    exact: true,
  },
  {
    path: '/tenant-profile/:id/edit',
    loader: () =>
      import(
        'src/view/tenantProfile/form/TenantProfileFormPage'
      ),
    permissionRequired: permissions.tenantProfileEdit,
    exact: true,
  },
  {
    path: '/tenant-profile/:id',
    loader: () =>
      import(
        'src/view/tenantProfile/view/TenantProfileViewPage'
      ),
    permissionRequired: permissions.tenantProfileRead,
    exact: true,
  },

  {
    path: '/online-order',
    loader: () =>
      import(
        'src/view/onlineOrder/list/OnlineOrderListPage'
      ),
    permissionRequired: permissions.onlineOrderRead,
    exact: true,
  },
  {
    path: '/online-order/new',
    loader: () =>
      import(
        'src/view/onlineOrder/form/OnlineOrderFormPage'
      ),
    permissionRequired: permissions.onlineOrderCreate,
    exact: true,
  },
  {
    path: '/online-order/importer',
    loader: () =>
      import(
        'src/view/onlineOrder/importer/OnlineOrderImporterPage'
      ),
    permissionRequired: permissions.onlineOrderImport,
    exact: true,
  },
  {
    path: '/online-order/:id/edit',
    loader: () =>
      import(
        'src/view/onlineOrder/form/OnlineOrderFormPage'
      ),
    permissionRequired: permissions.onlineOrderEdit,
    exact: true,
  },
  {
    path: '/online-order/:id',
    loader: () =>
      import(
        'src/view/onlineOrder/view/OnlineOrderViewPage'
      ),
    permissionRequired: permissions.onlineOrderRead,
    exact: true,
  },

  {
    path: '/event',
    loader: () =>
      import('src/view/event/list/EventListPage'),
    permissionRequired: permissions.eventRead,
    exact: true,
  },
  {
    path: '/event/new',
    loader: () =>
      import('src/view/event/form/EventFormPage'),
    permissionRequired: permissions.eventCreate,
    exact: true,
  },
  {
    path: '/event/importer',
    loader: () =>
      import('src/view/event/importer/EventImporterPage'),
    permissionRequired: permissions.eventImport,
    exact: true,
  },
  {
    path: '/event/:id/edit',
    loader: () =>
      import('src/view/event/form/EventFormPage'),
    permissionRequired: permissions.eventEdit,
    exact: true,
  },
  {
    path: '/event/:id',
    loader: () =>
      import('src/view/event/view/EventViewPage'),
    permissionRequired: permissions.eventRead,
    exact: true,
  },

  {
    path: '/host',
    loader: () => import('src/view/host/list/HostListPage'),
    permissionRequired: permissions.hostRead,
    exact: true,
  },
  {
    path: '/host/new',
    loader: () => import('src/view/host/form/HostFormPage'),
    permissionRequired: permissions.hostCreate,
    exact: true,
  },
  {
    path: '/host/importer',
    loader: () =>
      import('src/view/host/importer/HostImporterPage'),
    permissionRequired: permissions.hostImport,
    exact: true,
  },
  {
    path: '/host/:id/edit',
    loader: () => import('src/view/host/form/HostFormPage'),
    permissionRequired: permissions.hostEdit,
    exact: true,
  },
  {
    path: '/host/:id',
    loader: () => import('src/view/host/view/HostViewPage'),
    permissionRequired: permissions.hostRead,
    exact: true,
  },
].filter(Boolean);

const privateRoutesSubTenant = [
  {
    path: '/',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/sub-tenant/view/:url',
    loader: () =>
      import('src/view/subtenant/view/SubTenantAdmin'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/sub-tenant/:id/edit',
    loader: () =>
      import('src/view/subtenant/form/SubTenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/promotion',
    loader: () =>
      import('src/view/promotion/list/PromotionListPage'),
    permissionRequired: permissions.promotionRead,
    exact: true,
  },
  {
    path: '/promotion/new',
    loader: () =>
      import('src/view/promotion/form/PromotionFormPage'),
    permissionRequired: permissions.promotionCreate,
    exact: true,
  },

  {
    path: '/promotion/:id/edit',
    loader: () =>
      import('src/view/promotion/form/PromotionFormPage'),
    permissionRequired: permissions.promotionEdit,
    exact: true,
  },
  {
    path: '/promotion/:id',
    loader: () =>
      import('src/view/promotion/view/PromotionViewPage'),
    permissionRequired: permissions.promotionRead,
    exact: true,
  },

  {
    path: '/sitemap',
    loader: () =>
      import('src/view/sitemap/list/SitemapListPage'),
    permissionRequired: permissions.sitemapRead,
    exact: true,
  },
  {
    path: '/sitemap/new',
    loader: () =>
      import('src/view/sitemap/form/SitemapFormPage'),
    permissionRequired: permissions.sitemapCreate,
    exact: true,
  },

  {
    path: '/sitemap/:id/edit',
    loader: () =>
      import('src/view/sitemap/form/SitemapFormPage'),
    permissionRequired: permissions.sitemapEdit,
    exact: true,
  },
  {
    path: '/sitemap/:id',
    loader: () =>
      import('src/view/sitemap/view/SitemapViewPage'),
    permissionRequired: permissions.sitemapRead,
    exact: true,
  },

  {
    path: '/sitemap-categories',
    loader: () =>
      import(
        'src/view/sitemapCategories/list/SitemapCategoriesListPage'
      ),
    permissionRequired: permissions.sitemapCategoriesRead,
    exact: true,
  },
  {
    path: '/sitemap-categories/new',
    loader: () =>
      import(
        'src/view/sitemapCategories/form/SitemapCategoriesFormPage'
      ),
    permissionRequired: permissions.sitemapCategoriesCreate,
    exact: true,
  },

  {
    path: '/sitemap-categories/:id/edit',
    loader: () =>
      import(
        'src/view/sitemapCategories/form/SitemapCategoriesFormPage'
      ),
    permissionRequired: permissions.sitemapCategoriesEdit,
    exact: true,
  },
  {
    path: '/sitemap-categories/:id',
    loader: () =>
      import(
        'src/view/sitemapCategories/view/SitemapCategoriesViewPage'
      ),
    permissionRequired: permissions.sitemapCategoriesRead,
    exact: true,
  },

  {
    path: '/event',
    loader: () =>
      import('src/view/event/list/EventListPage'),
    permissionRequired: permissions.eventRead,
    exact: true,
  },
  {
    path: '/event/new',
    loader: () =>
      import('src/view/event/form/EventFormPage'),
    permissionRequired: permissions.eventCreate,
    exact: true,
  },

  {
    path: '/event/:id/edit',
    loader: () =>
      import('src/view/event/form/EventFormPage'),
    permissionRequired: permissions.eventEdit,
    exact: true,
  },
  {
    path: '/event/:id',
    loader: () =>
      import('src/view/event/view/EventViewPage'),
    permissionRequired: permissions.eventRead,
    exact: true,
  },

  {
    path: '/host',
    loader: () => import('src/view/host/list/HostListPage'),
    permissionRequired: permissions.hostRead,
    exact: true,
  },
  {
    path: '/host/new',
    loader: () => import('src/view/host/form/HostFormPage'),
    permissionRequired: permissions.hostCreate,
    exact: true,
  },

  {
    path: '/host/:id/edit',
    loader: () => import('src/view/host/form/HostFormPage'),
    permissionRequired: permissions.hostEdit,
    exact: true,
  },
  {
    path: '/host/:id',
    loader: () => import('src/view/host/view/HostViewPage'),
    permissionRequired: permissions.hostRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
  privateRoutesSubTenant,
};
