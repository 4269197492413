import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import restaurant from 'src/modules/restaurant/restaurantReducers';
import menu from 'src/modules/menu/menuReducers';
import menuItem from 'src/modules/menuItem/menuItemReducers';
import menuSection from 'src/modules/menuSection/menuSectionReducers';
import room from 'src/modules/room/roomReducers';
import place from 'src/modules/place/placeReducers';
import promotion from 'src/modules/promotion/promotionReducers';
import sitemap from 'src/modules/sitemap/sitemapReducers';
import sitemapCategories from 'src/modules/sitemapCategories/sitemapCategoriesReducers';
import tenantProfile from 'src/modules/tenantProfile/tenantProfileReducers';
import onlineOrder from 'src/modules/onlineOrder/onlineOrderReducers';
import event from 'src/modules/event/eventReducers';
import host from 'src/modules/host/hostReducers';
import subtenant from 'src/modules/subTenant/subTenantReducers';

import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    subtenant,
    plan,
    user,
    auditLog,
    settings,
    restaurant,
    menu,
    menuItem,
    menuSection,
    room,
    place,
    promotion,
    sitemap,
    sitemapCategories,
    tenantProfile,
    onlineOrder,
    event,
    host,
  });
