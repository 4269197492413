import { tenantSubdomain } from '../tenant/tenantSubdomain';
import { subtenantDomain } from 'src/modules/subTenant/subTenantSubdomain';

export default class AuthCurrentTenant {
  static selectAndSaveOnStorageFor(currentUser) {
    let tenant;
    if (!currentUser) {
      return null;
    }

    if (!currentUser.tenants) {
      return null;
    }

    const activeTenants = currentUser.tenants.filter(
      (tenantUser) => tenantUser.status === 'active',
    );

    if (!activeTenants || !activeTenants.length) {
      return null;
    }

    const tenantId = this.isSubTenant()
      ? this.getSubTenantId()
      : this.get();

    if (currentUser?.subTenant) {
      tenant = currentUser.subTenants.find(
        (st) => st.id === currentUser.subTenant,
      );

      if (
        tenant &&
        subtenantDomain.isEnabled &&
        !subtenantDomain.isSubdomainOf(tenant.url)
      ) {
        return subtenantDomain.redirectAuthenticatedTo(
          tenant.url,
          tenant.parentTenant.name,
        );
      }
    } else if (this.isSubTenant()) {
      if (tenantId) {
        tenant = currentUser.subTenants.find(
          (st) => st.id === tenantId,
        );
      }

      if (
        tenant &&
        subtenantDomain.isEnabled &&
        !subtenantDomain.isSubdomainOf(tenant.url)
      ) {
        return subtenantDomain.redirectAuthenticatedTo(
          tenant.url,
          tenant.parentTenant.name,
        );
      }
    } else {
      if (tenantId) {
        tenant = activeTenants
          .map((tenantUser) => tenantUser.tenant)
          .find((tenant) => tenant.id === tenantId);
      }

      tenant = tenant || activeTenants[0].tenant;

      if (
        tenant &&
        tenantSubdomain.isEnabled &&
        !tenantSubdomain.isSubdomainOf(tenant.url)
      ) {
        return tenantSubdomain.redirectAuthenticatedTo(
          tenant.url,
        );
      }
    }

    this.set(tenant);
    return tenant;
  }

  static get() {
    const tenantASString =
      localStorage.getItem('tenant') || null;

    if (tenantASString) {
      const tenant = JSON.parse(tenantASString);
      if (tenant && tenant?.parentTenant)
        return tenant.parentTenant.id;
      return tenant.id;
    }

    return null;
  }

  static getSubTenantId() {
    const tenantASString =
      localStorage.getItem('tenant') || null;
    if (tenantASString) {
      return JSON.parse(tenantASString)._id;
    }
    return null;
  }

  static isSubTenant() {
    return localStorage.getItem('subTenant') ? true : false;
  }

  static getSettings() {
    const tenantASString =
      localStorage.getItem('tenant') || null;

    if (tenantASString) {
      const tenant = JSON.parse(tenantASString);

      if (tenant) {
        if (Array.isArray(tenant.settings)) {
          if (tenant.settings.length) {
            return tenant.settings[0];
          }
        } else {
          return tenant.settings;
        }
      }
    }

    return null;
  }

  static set(tenant) {
    if (!tenant) {
      return this.clear();
    }

    localStorage.setItem('tenant', JSON.stringify(tenant));
  }

  static clear() {
    localStorage.removeItem('tenant');
  }
}
