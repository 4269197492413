import TenantService from 'src/modules/tenant/tenantService';
import selectors from 'src/modules/tenant/subtenant/subtenantselectors';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'SUB_TENANT_LIST';

const subTenantList = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  FIND_FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FIND_FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FIND_FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  doClearAllSelected() {
    return {
      type: subTenantList.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: subTenantList.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: subTenantList.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: subTenantList.RESETED,
    });

    dispatch(subTenantList.doFetch());
  },

  doChangePagination:
    (pagination) => async (dispatch, getState) => {
      dispatch({
        type: subTenantList.PAGINATION_CHANGED,
        payload: pagination,
      });

      dispatch(subTenantList.doFetchCurrentFilter());
    },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: subTenantList.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(subTenantList.doFetchCurrentFilter());
  },

  doFetchCurrentFilter:
    () => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState());
      const rawFilter = selectors.selectRawFilter(
        getState(),
      );
      dispatch(
        subTenantList.doFetch(filter, rawFilter, true),
      );
    },

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: subTenantList.FIND_FETCH_STARTED,
      });
      const response = await TenantService.find(id);

      dispatch({
        type: subTenantList.FIND_FETCH_SUCCESS,
        payload: {
          rows: response.subtenants,
          count: response.subtenants.length,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: subTenantList.FIND_FETCH_ERROR,
      });
    }
  },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: subTenantList.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        });

        const response = await TenantService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: subTenantList.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: subTenantList.FETCH_ERROR,
        });
      }
    },
};

export default subTenantList;
