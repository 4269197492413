import list from 'src/modules/host/list/hostListReducers';
import form from 'src/modules/host/form/hostFormReducers';
import view from 'src/modules/host/view/hostViewReducers';
import destroy from 'src/modules/host/destroy/hostDestroyReducers';
import importerReducer from 'src/modules/host/importer/hostImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
