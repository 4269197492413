import list from 'src/modules/menuSection/list/menuSectionListReducers';
import form from 'src/modules/menuSection/form/menuSectionFormReducers';
import view from 'src/modules/menuSection/view/menuSectionViewReducers';
import destroy from 'src/modules/menuSection/destroy/menuSectionDestroyReducers';
import importerReducer from 'src/modules/menuSection/importer/menuSectionImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
